.layout {
    min-height: 100vh;
}

.desktop-header-search {
    height: 50px;
    padding: 0 20px;
    line-height: 50px;
    background-color: #f0f2f5;
    color: #64b48f;
}

.hedaer2-back{
    color: #2a2a2a;
    font-size: 150%;
    margin-top: 15px;
    margin-right: 15px;
    cursor: pointer;
}

.track-header {
    font-size: 140%;
    color: #000;
}

.form-title{
    font-weight: bold;
    text-align: center;
    font-size: 120%;
    color: #292929;
    padding-top: 20px;
}

.form-description{
    text-align: center;
    font-size: 90%;
    color: #858585;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 8%;
    padding-right: 8%;
}

.text-small-grey {
    font-size: 90%;
    color: #8e8e8e;
    padding-bottom: 7px;
}

.header-logo{
    height: 50px;
    margin-right: 20px;
}

.mini-logo{
    height: 70px;
    margin-top: -10px;
}

.headerTitle{
    color: #292929;
    font-size: 125%;
    text-shadow: 1px 1px #dadada;
}

.header-control{
    color: black;
    float: right;
    font-size: 150%;
    padding-top: 15px;
    cursor: pointer;
}

.h1{
    padding-top: 20px;
    margin-top: 10px;
    font-size: 200%;
    text-align: center;
}

.h2{
    font-size: 150%;
    text-align: center;
    padding: 15px 4.5%;
}

.intro{
    text-align: justify;
    font-size: 128%;
    padding: 15px 4.5%;
    color: #6e6d6d;
}

.pDesc{
    text-align: justify;
    color: #6e6d6d;
}

.boxInseide{
    color: #6e6d6d;
    padding-top: 10px;
    font-size: 110%;
}
